





















import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    isDisabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    totalCount: {
      type: String as PropType<string>,
      default: null,
    },
    exportSpinner: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(_, context) {
    const requestExport = () => {
      context.emit('request-export')
    }
    const requestImport = () => {
      context.emit('request-import')
    }
    return {
      requestExport,
      requestImport,
    }
  },
})
