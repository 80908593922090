















import { defineComponent } from '@vue/composition-api'

import PageView from '@/components/templates/h/new-store-bulk-order/new-store-bulk-order-sheet/PageView.vue'
import SettingImportModal from '@/components/templates/h/SettingImportModal.vue'
import { 
  NewStoreBulkOrderSheetExtractCondition,
  NewStoreBulkOrderSheetOutputCondition,
  ColumnLabel 
} from '@/types/entities'
import {
  TemplateFile,
  ResourceType,
  Where,
  WhereOp,
  OrderBy,
  OrderByDirection,
  OrderByNullsOption,
} from '@/types/generated/graphql'
import { EXPORT_FILENAME } from '@/utils/constant'
import importExportHelper from '@/utils/importExportHelper'
import { addDayYMD } from '@/utils/mixin'
import { setWhereValue } from '@/utils/searchConditionDef'

const DEFAULT_EXTRACT_CONST = {validData: addDayYMD(1)}

export default defineComponent({
  components: { PageView, SettingImportModal },
  layout: 'sidebar',
  created() {
    this.helper = new importExportHelper(this)
  },
  data() {
    return {
      resourceType: ResourceType.UiPNewStoreBulkOrderSheet,
      templateFile: TemplateFile.UiPNewStoreBulkOrderSheet_01,
      isDisable: true,
      defaultDateValue: DEFAULT_EXTRACT_CONST.validData,
      extractCondition: {
        storeCd: "",
        orderDate: ""
      },
    }
  },
  methods: {
    /**
     * インポート開始処理
     */
    async importFileUpload(files: File[]) {
      console.log('importFileUpload', { files })
      //this.helper.import({ files: files, resourceType: this.resourceType })
    },
    /**
     * エクスポート処理
     */
    async requestExport() {
      const query = this._createQueryString()
      await this.helper.export({
        query,
        jmespathQuery:
          'data.vUiPNewStoreBulkOrderSheets.edges[].node',
        templateFile: this.templateFile,
        filenameExport: EXPORT_FILENAME.new_store_bulk_order_sheet,
        columnLabelMapping: this._create_column_label_mapping(),
      })
    },
    /**
     * ファイル抽出Queryを構築する
     */
    _createQueryString(): string {
      const orderBy = this._createOrderByStatement()
      const where: Where[] = this._createWhere()
      const rowQuery = `
        query c {
          vUiPNewStoreBulkOrderSheets(
            where: ${this.helper.generateQueryWhereString(where)}
            orderBy: ${this.helper.generateQueryOrderByString(orderBy)}
          ) {
            edges {
              node {
                storeCd
                saleItemCd
                saleItemName
                orderNum
                category0Cd
                category1Cd
                vendorCd
                vendorCdName
                requiredDays
              }
            }
          }
        }
        `
      return this.helper.generateQueryString({
        rowQuery: rowQuery,
        resourceType: this.resourceType,
        where,
      })
    },
    /**
     * ファイル抽出QueryのWhereパラメータを構築する
     */
     _createWhere() {
      const ITEM_SEARCH_CONDITION: Where[] = [
        // 店舗の抽出条件
        {
          field: ['storeCd'],
          op: WhereOp.In,
          value: [''],
        },
        {// 有効な商品のみ
          field: ['inactiveFlg'],
          op: WhereOp.In,
          value: ['0'],
        },
      ]
      const where = JSON.parse(JSON.stringify(ITEM_SEARCH_CONDITION))
      setWhereValue(where, 'storeCd', this.extractCondition.storeCd)
      console.log({ where })
      return where
    },
    /**
     * ファイル抽出QueryのOrderByパラメータを構築する
     */
    _createOrderByStatement(): OrderBy[] {
      return [
        {
          field: 'storeCd',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'category1Cd',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'saleItemCd',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
      ]
    },
    changeExtractCondition(cond: NewStoreBulkOrderSheetExtractCondition) {
        console.log("store = " + cond.storeCd + ", date=" + cond.orderDate)
        if(cond.storeCd != '' &&
          cond.orderDate != ''
        ){
          this.isDisable = false
        }else{
          this.isDisable = true
        }
        this.extractCondition = cond
    },
    _create_column_label_mapping() {
      const columnLabelMapping: ColumnLabel[] = [
        {
          from: 'storeCd',
          to: '',
        },
        {
          from: 'orderDate',
          to: '',
        },
        {
          from: 'deliveryDate',
          to: '',
        },
        {
          from: 'saleItemCd',
          to: '',
        },
        {
          from: 'saleItemName',
          to: '',
        },
        {
          from: 'orderNum',
          to: '',
        },
        {
          from: 'category0Cd',
          to: '',
        },
        {
          from: 'category1Cd',
          to: '',
        },
        {
          from: 'vendorCd',
          to: '',
        },
        {
          from: 'vendorCdName',
          to: '',
        },
        {
          from: 'requiredDays',
          to: '',
        },
        {// 固定値を流し込みたいので設定を流用する
          from: '_value_orderDate',
          to: this.extractCondition.orderDate,
        },
      ]
      console.log({ columnLabelMapping })
      return columnLabelMapping
    },
  },
})
