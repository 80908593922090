




























import { defineComponent, PropType, ref } from '@vue/composition-api'

import { today } from '@/utils/mixin'

/**
 * 日付入力フォーム
 *
 * sの画面で入力に必要な基本パターンをセットしたもの
 */
export default defineComponent({
  props: {
    /**
     * 入力値。v-model での利用を想定
     */
    value: {
      type: String as PropType<string>,
    },
    /**
     * YYYY を表示するか否か
     */
    hasYYYY: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /**
     * 入力可能な日付の min 日付
     * これより前の日付は指定できない
     */
    min: {
      type: [String, Date] as PropType<string | Date>,
      default: '',
    },
    /**
     * 入力可能な日付の max 日付
     * これより後の日付は指定できない
     */
    max: {
      type: String as PropType<string>,
      default: '',
    },
    /**
     * フォームの大きさ
     * bootstrap-vue の size に相当
     */
    size: {
      type: String as PropType<string>,
      default: 'sm',
    },
    /**
     * フォームを無効にするか否か
     */
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /**
     * 開始曜日
     */
    startDayOfWeek: {
      type: Number as PropType<number>,
      default: 0,
    },
    dateDisabledFn: {
      type: Function as unknown as () => boolean,
      default: () => false,
    },
    required: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    defaultDateValue: {
      type: String as PropType<string>,
      default: () => '',
    },
  },
  setup(props, context) {
    const inputDate = ref();
    inputDate.value = props.defaultDateValue
    console.log("di setup: " + props.defaultDateValue)
    return {
      context,
      today,
      inputDate
    }
  },
})
