





















import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
} from '@vue/composition-api'

import HLine from '@/components/atoms/HLine.vue'
import DateSelect from '@/components/organisms/h/item/date/DateSelect.vue'
import ItemHierarchyGroup from '@/components/organisms/h/item/hierarchy/ItemHierarchyGroup.vue'
import ExtractConditionLayout from '@/components/organisms/h/layout/ExtractConditionLayout.vue'
import {
  NewStoreBulkOrderSheetExtractCondition,
  itemHierarchyGroupExtractCondition,
  itemHierarchyUseList,
  dateSelectExtractCondition,
} from '@/types/entities'

export default defineComponent({
  components: {
    ExtractConditionLayout,
    ItemHierarchyGroup,
    HLine,
    DateSelect
  },
  props: {
    defaultDateValue: {
      type: String as PropType<string>,
      default: () => '',
    },
  },
  setup(props, context) {
    /*********************************
     * 表示する選択肢
     *********************************/
     const storeUseList = ref<itemHierarchyUseList>([['StoreSingle']])

    /*********************************
     * データ
     *********************************/
    const extractConditionOfStore = ref<itemHierarchyGroupExtractCondition>({})
    const extractConditionOfDate = ref<dateSelectExtractCondition>({})
    extractConditionOfStore.value.store = ['']
    extractConditionOfDate.value.dateSelect = props.defaultDateValue

    /*********************************
     * emitデータの作成
     *********************************/
    // いずれかの抽出条件変更時の処理
    const whereValidData = ref<string[]>([props.defaultDateValue])
    const changeExtractCondition = () => {
      let storeSelect = ''
      if(extractConditionOfStore.value.store &&
      extractConditionOfStore.value.store.length > 0){
        storeSelect = extractConditionOfStore.value.store[0]
      }
      let selectDate = ''
      if(extractConditionOfDate.value.dateSelect){
        selectDate = extractConditionOfDate.value.dateSelect
      }

      const base: NewStoreBulkOrderSheetExtractCondition = {
        storeCd: storeSelect,
        orderDate: selectDate
      }

      let cond = { ...base }
      // console.log('changeExtractCondition', { cond })
      context.emit('change-extract-condition', cond)
    }

    /*********************************
     * 以下は各抽出条件変更時の処理
     *********************************/
     const changeExtractConditionOfDate = (
      params: dateSelectExtractCondition
    ) => {
      extractConditionOfDate.value = params
      changeExtractCondition()
    }
     const changeExtractConditionOfStore = (
      params: itemHierarchyGroupExtractCondition
    ) => {
      extractConditionOfStore.value = params
      changeExtractCondition()
    }
    const requiredItemInput = [
      '店舗と発注日を選択',
    ]
    const defaultValue = props.defaultDateValue
    return {
      requiredItemInput,
      storeUseList,
      whereValidData,
      changeExtractConditionOfStore,
      changeExtractConditionOfDate,
      defaultValue
    }
  },
})
