
















import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
} from '@vue/composition-api'

import DateInput from '@/components/molecules/c/DateInput.vue'
import ExtractConditionDateInputLayout from '@/components/organisms/h/layout/ExtractConditionDateInputLayout.vue'
import ExtractConditionRowLayout from '@/components/organisms/h/layout/ExtractConditionRowLayout.vue'
import {
  dateSelect,
  dateSelectExtractCondition,
  itemHierarchyGroupExtractCondition,
} from '@/types/entities'

export default defineComponent({
  components: {
    DateInput,
    ExtractConditionDateInputLayout,
    ExtractConditionRowLayout,
  },
  props: {
    title: {
      type: String as PropType<string>,
      default: '',
    },
    defaultDateValue: {
      type: String as PropType<string>,
      default: () => '',
    },
    required: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, context) {
    // いずれかの抽出条件変更時の処理
    const changeExtractCondition = () => {
      const cond: dateSelectExtractCondition = {
        dateSelect: dateSelect.value,
      }
      context.emit('change-extract-condition', cond)
    }
    /*********************************
     * 以下は各抽出条件変更時の処理
     *********************************/
    const defaultValue = props.defaultDateValue
    const dateSelect = ref<string>(props.defaultDateValue)

    watch(dateSelect, (newVal) => {
      changeExtractCondition()
    })
    return { dateSelect, defaultValue }
  },
})
